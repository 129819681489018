import * as React from 'react'
import SumsubWebSdk from '@sumsub/websdk-react'
import {
  useParams,
} from 'react-router-dom'

import NotFound from '../not_found'

function Verification() {
  let { accessToken } = useParams<"accessToken">()
  let { lang } = useParams<"lang">()

  if (!accessToken) {
    return <NotFound />;
  }

  const errorHandler = (err: any) => console.log('errorHandler: ', err)
  const messageHandler = (err: any) => console.log('messageHandler: ', err)
  const expirationHandler = (err: any) => console.log('expirationHandler: ', err)

  return (
    <div>
      <SumsubWebSdk
        accessToken={accessToken}
        config={{
          lang
        }}
        // @ts-ignore
        expirationHandler={expirationHandler}
        onMessage={messageHandler}
        onError={errorHandler}
      />
    </div>
  );
}

export default Verification
