import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import Verification from './pages/verification'
import NotFound from './pages/not_found'
import './App.css'

const router = createBrowserRouter([
  {
    path: "/verifications/:accessToken/:lang",
    element: <Verification />,
  },
  {
    path: "/",
    element: <NotFound />,
    errorElement: <NotFound />,
  },
])


function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App
