import * as React from 'react'
import './index.css'

function NotFound() {
  return (
    <div className="not_found">
      <h2>Not found</h2>
    </div>
  )
}

export default NotFound